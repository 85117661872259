import { ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { ISplitcart, ISplitcartState } from '../reducers/SplitcartReducer'
import { ISearchState } from '../containers/Search'

import { firestore } from '../firebase/firebase.util'
import { SplitcartViewModel } from '../viewModels/SplitcartViewModel'

export enum SplitcartActionTypes {
    GET_ALL = 'GET_ALL',
    FILTER_SPLITCARTS = 'FILTER_SPLITCARTS'
}

export interface ISplitcartGetAllAction {
    type: SplitcartActionTypes.GET_ALL
    splitcarts: ISplitcart[]
}

export interface ISplitcartFilterAction {
    type: SplitcartActionTypes.FILTER_SPLITCARTS
    splitcarts: ISplitcart[]
}

export type SplitcartActions = ISplitcartGetAllAction | ISplitcartFilterAction

export const getAllSplitcarts: ActionCreator<ThunkAction<Promise<any>, ISplitcartState, null, ISplitcartGetAllAction>> = () => {
    return async (dispatch: Dispatch<ISplitcartGetAllAction>) => {
        try {
            let splitcarts: ISplitcart[] = []

            const querySnapshot = (await firestore.collection('splitcarts').get()).docs
            splitcarts = querySnapshot.map(doc => {
                return doc.data() as ISplitcart
            })
            .filter(splitcart => {
                return splitcart.id !== undefined
            })

            dispatch({
                splitcarts: splitcarts,
                type: SplitcartActionTypes.GET_ALL
            })
        } catch(err) {
            console.log(err)
        }
    }
}

export const filterSplitcarts: ActionCreator<ThunkAction<Promise<any>, ISplitcartState, null, ISplitcartFilterAction>> = (
    state: ISearchState
) => {
    return async (dispatch: Dispatch<ISplitcartFilterAction>) => {
        try {
            let splitcarts: ISplitcart[] = []

            const querySnapshot = (await firestore.collection('splitcarts').get()).docs
            splitcarts = querySnapshot.map(doc => {
                return doc.data() as ISplitcart
            })
            .filter(splitcart => {
                return splitcart.id !== undefined
            })

            if(splitcarts.length !== 0) {
                splitcarts = state.canceled ? splitcarts.filter(splitcart => {
                    return splitcart.wasCanceled !== undefined && splitcart.wasCanceled
                }) : splitcarts

                splitcarts = state.funded ? splitcarts.filter(splitcart => {
                    return splitcart.funded !== undefined && splitcart.funded
                }) : splitcarts 

                splitcarts = state.giftCard ? splitcarts.filter(splitcart => {
                    return splitcart.createGiftCard !== undefined && splitcart.createGiftCard
                }) : splitcarts 

                splitcarts = state.returned ? splitcarts.filter(splitcart => {
                    return splitcart.returnInfo !== undefined && splitcart.returnInfo.wasReturned !== undefined && splitcart.returnInfo.wasReturned
                }) : splitcarts

                splitcarts = state.term.length !== 0 ? splitcarts.filter(splitcart => {
                    const viewModel = new SplitcartViewModel(splitcart)
                    const term = state.term.trim()

                    // const matchingSplitterNames = viewModel.splitterNames().filter(name => name.includes(term))
                    // const matchingSplitterEmails = viewModel.splitterEmails().filter(email => email.includes(term))

                    return splitcart.id.startsWith(term) ||
                            viewModel.organizerName().includes(term) ||
                            splitcart.organizer.email.includes(term) || 
                            splitcart.organizer.phone.includes(term) ||
                            viewModel.receiverName().includes(term) || 
                            viewModel.receiverEmail().includes(term) ||
                            viewModel.receiverPhone().includes(term)
                            // matchingSplitterNames.length === 1 ||
                            // matchingSplitterEmails.length === 1
                }) : splitcarts
            }

            dispatch({
                splitcarts: splitcarts,
                type: SplitcartActionTypes.FILTER_SPLITCARTS
            })
        } catch(err) {
            console.log(err)
        }
    }
}