import React from 'react'

interface IProps {
    handleChange: (event: React.FormEvent<HTMLInputElement>) => void
}

const FilterForm: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <form>
            <div className="ui checkbox">
                <input type="checkbox" name="giftCard" onChange={props.handleChange} />
                <label style={{ paddingRight: '20px' }}>Gift Card</label>
            </div>
            <div className="ui checkbox">
                <input type="checkbox" name="canceled" onChange={props.handleChange} />
                <label style={{ paddingRight: '20px' }}>Canceled</label>
            </div>
            <div className="ui checkbox">
                <input type="checkbox" name="funded" onChange={props.handleChange} />
                <label style={{ paddingRight: '20px' }}>Funded</label>
            </div>
            <div className="ui checkbox">
                <input type="checkbox" name="returned" onChange={props.handleChange} />
                <label style={{ paddingRight: '20px' }}>Returned</label>
            </div>
            <div className="ui checkbox">
                <input type="checkbox" name="inProgress" onChange={props.handleChange} />
                <label>In Progress</label>
            </div>
        </form>
    )
}

export default FilterForm