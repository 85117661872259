import { ISplitcart } from "../reducers/SplitcartReducer"

export class SplitcartViewModel {
    splitcart: ISplitcart

    constructor(splitcart: ISplitcart) {
        this.splitcart = splitcart
    }

    organizerName = () => {
        if(this.splitcart.organizer.lastName !== '') {
            return this.splitcart.organizer.firstName + ' ' + this.splitcart.organizer.lastName
        } else {
            return this.splitcart.organizer.firstName
        }
    }

    receiverName = () => {
        const receiver = this.splitcart.receiver
        if(receiver !== undefined) {
            if(receiver.lastName !== '') {
                return receiver.firstName + ' ' + receiver.lastName
            } else {
                return receiver.firstName
            }
        } else {
            return ''
        }
    }

    receiverEmail = () => {
        const receiver = this.splitcart.receiver
        return receiver !== undefined ? receiver.email : ''
    }

    receiverPhone = () => {
        const receiver = this.splitcart.receiver
        return receiver !== undefined ? receiver.phone : ''
    }

    isCompleted = () => {
        return this.daysleft() < 1 || this.progress() === 1.0 || this.funded() || this.wasCanceled()
    }

    progress = () => {
        let numberPayed = this.splitcart.splitters !== undefined ? (this.splitcart.splitters.filter(splitter => splitter.status === 0)).length : 0
        if(numberPayed === 0) {
            return 0.0
        } else if(this.splitcart.splitters.length === 0) {
            return 1.0
        } else {
            if(this.funded()) {
                return 1.0
            } else {
                let percent = (numberPayed / this.numberOfSplitters()) * 100.0
                return percent / 100.0
            }
        }
    }

    wasCanceled = () => {
        return this.splitcart.wasCanceled !== undefined && this.wasCanceled
    }

    coverCost = () => {
        return this.splitcart.organizer.cover !== undefined && this.splitcart.organizer.cover
    }

    createGiftCard = () => {
        return this.splitcart.createGiftCard !== undefined && this.splitcart.createGiftCard
    }

    funded = () => {
        const isFunded = this.splitcart.funded !== undefined && this.splitcart.funded
        return isFunded ? true : (this.coverCost() || this.createGiftCard())
    }

    numberOfSplitters = () => {
        return this.splitcart.splitters.length
    }

    daysleft = () => {
        const endDate = new Date(this.splitcart.endDate)
        const currentDate = new Date()
        const diff = Math.abs(currentDate.getTime() - endDate.getTime())
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24))

        if(diffDays === 0) {
            return 2
        } else if(diffDays < 0) {
            return diffDays
        } else {
            return diffDays + 1
        }
    }

    splitterNames = () => {
        if(this.splitcart.splitters !== undefined) {
            return this.splitcart.splitters.map(splitter => {
                if(splitter.lastName !== '') {
                    return splitter.firstName + ' ' + splitter.lastName
                } else {
                    return splitter.firstName
                }
            })
        } else {
            return []
        }
    }

    splitterEmails = () => {
        if(this.splitcart.splitters !== undefined) {
            return this.splitcart.splitters.map(splitter => {
                return splitter.email !== '' ? splitter.email : ''
            }).filter(email => email !== '')
        } else {
            return []
        }
    }
}