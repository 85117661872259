import { applyMiddleware, combineReducers, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { splitcartReducer, ISplitcartState } from '../reducers/SplitcartReducer'
import { userReducer, IUserState } from '../reducers/UserReducer'

export interface IAppState {
    splitcartState: ISplitcartState
    userState: IUserState
}

const rootReducer = combineReducers<IAppState>({
    splitcartState: splitcartReducer,
    userState: userReducer
})

export default function configureStore(): Store<IAppState, any> {
    const store = createStore(rootReducer, undefined, composeWithDevTools(applyMiddleware(thunk)))
    return store
}