import React from 'react'

interface IProps {
    handleChange: (event: React.FormEvent<HTMLInputElement>) => void
    handleReset: (event: React.FormEvent<HTMLFormElement>) => void
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    term: string
}

const SearchInput: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
            <div className="ui input focus" style={{ paddingRight: '20px', width: '350px' }}>
                <input type="text" name="term" value={props.term} onChange={props.handleChange} />
            </div>
            <button className="ui button" type="submit">Submit</button>
            <button className="ui button" type ="reset">Clear</button>
        </form>
    )
}

export default SearchInput