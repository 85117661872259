import React from 'react'
import CSS from 'csstype'

interface IProps {
    handleSignOut: () => void
}

const container: CSS.Properties = {
    width: '100%',
    height: '50px'
}

const wrapperStyles: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr'
}

const itemStyles: CSS.Properties = {
    paddingRight: '20px',
    textAlign: 'right'
}

const TopBar: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div style={container}>
            <div style={wrapperStyles}>
                <div style={itemStyles}></div>
                <div style={itemStyles}></div>
                <div style={itemStyles}>
                    <button className="ui button" onClick={props.handleSignOut}>Logout</button>
                </div>
            </div>
        </div>
    )
}

export default TopBar