import { ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { FirebaseUser, signIn, firestore, getUser, auth} from '../firebase/firebase.util'

import { IUser, IUserState } from '../reducers/UserReducer'

export enum UserActionTypes {
    GET_CURRENT_USER = 'GET_CURRENT_USER',
    SIGN_IN_USER = 'SIGN_IN_USER',
    SIGN_OUT_USER = 'SIGN_OUT_USER'
}

export interface IUserGetCurrentUserAction {
    type: UserActionTypes.GET_CURRENT_USER
    currentUser: FirebaseUser
}

export interface IUserSignInUserAction {
    type: UserActionTypes.SIGN_IN_USER
    userInfo: IUser | null
    currentUser: FirebaseUser
}

export interface IUserSignOutUserAction {
    type: UserActionTypes.SIGN_OUT_USER
}

export type UserActions = IUserSignInUserAction | IUserGetCurrentUserAction | IUserSignOutUserAction

export const getCurrentUser: ActionCreator<ThunkAction<Promise<any>, IUserState, null, IUserGetCurrentUserAction>> = () => {
    return async (dispatch: Dispatch<IUserGetCurrentUserAction>) => {
        try {
            const user = await getUser()
            dispatch({
                currentUser: user,
                type: UserActionTypes.GET_CURRENT_USER
            })
        } catch(err) {
            console.log(err)
        }
    }
}

export const signInUser: ActionCreator<ThunkAction<Promise<any>, IUserState, null, IUserSignInUserAction>> = (
    email: string,
    password: string
) => {
    return async (dispatch: Dispatch) => {
        try {
            // Sign the user in
            const userCredentials = await signIn(email, password)
            // Get the user's data from the firstore
            const document = (await firestore.collection('users').doc(userCredentials.uid).get()).data()
            // Cast the document info to IUser unless it is undefined
            const userInfo = document !== undefined ? document as IUser : null

            dispatch({
                userInfo: userInfo,
                currentUser: userCredentials,
                type: UserActionTypes.SIGN_IN_USER
            })
        } catch(err) {
            console.log(err)
        }
    }
}

export const signOutUser: ActionCreator<ThunkAction<Promise<any>, IUserState, null, IUserSignOutUserAction>> = () => {
    return async (dispatch: Dispatch) => {
        try {
            await auth.signOut()

            dispatch({
                type: UserActionTypes.SIGN_OUT_USER
            })
        } catch(err) {
            console.log(err)
        }
    }
}