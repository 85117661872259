import React, { useState } from 'react'
import CSS from 'csstype'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { signInUser } from '../actions/UserActions'
import { FirebaseUser } from '../firebase/firebase.util'
import { IAppState } from '../store/store'
import { RouteComponentProps } from 'react-router-dom'
import * as Constants from '../constants/routes'

const containerStyles: CSS.Properties = {
    width: '100%',
    height: '100%',
    backgroundColor: 'red'
}

const formStyles: CSS.Properties = {
    position: 'fixed',
    width: '300px',
    height: '200px',
    left: '50%',
    top: '50%',
    marginLeft: '-100px',
    marginTop: '-100px'
}

const itemStyles: CSS.Properties = {
    paddingBottom: '20px',
    width: '300px'
}

interface IProps extends RouteComponentProps<any> {
    user: FirebaseUser
}

interface IDispatchProps {
    signIn: (email: string, password: string) => void
}

const LoginForm: React.FunctionComponent<IProps & IDispatchProps> = (props: IProps & IDispatchProps) => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        if(event.currentTarget.name === 'email') {
            setEmail(event.currentTarget.value)
        }
        if(event.currentTarget.name === 'password') {
            setPassword(event.currentTarget.value)
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        if(email === '' || password === '') {
            alert('Please enter both an email and password')
        } else if(!adminCheck()) {
            alert('You are not an admin!')
        } else {
            props.signIn(email, password)
            props.history.push(Constants.ADMIN)
        }
    }

    const adminCheck = (): boolean => {
        const validEmails: string[] = ['byoung@hanndev.com', 'oswald@hanndev.com', 'eianaldrich@outlook.com']
        const result = validEmails.filter(_email => _email === email)
        return result.length === 1
    }

    return(
        <div style={containerStyles}>
            <form onSubmit={handleSubmit} style={formStyles}>
                <div className="ui input" style={itemStyles}>
                    <input type="text" name="email" placeholder="Email" onChange={handleChange} />
                </div>
                <br />
                <div className="ui input" style={itemStyles}>
                    <input type="password" name="password" placeholder="Password" onChange={handleChange} />
                </div>
                <br />
                <button className="ui button" style={{ width: '300px' }} type="submit">
                    Login
                </button>
            </form>
        </div>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
        user: store.userState.currentUser
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{},{}, any>, props: IProps): IDispatchProps => {
    return {
        signIn: async (email, password) => {
            dispatch(signInUser(email, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)