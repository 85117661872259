import React from 'react'
import CSS from 'csstype'

import { ISplitcart } from '../reducers/SplitcartReducer'
import SplitcartListItem from './SplitcartListItem'

interface IProps {
    splitcarts: ISplitcart[]
}

interface IFunctionProps {
    handleSplitcartSelect: (id: string) => void
}

const wrapperStyles: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 2fr 2fr',
    padding: '10px',
    borderBottom: '1px solid black'
}

const itemStyles: CSS.Properties = {
    paddingLeft: '20px',
    textAlign: 'left'
}

const SplitcartList: React.FunctionComponent<IProps & IFunctionProps> = (props: IProps & IFunctionProps) => {

    return (
        <div>
            <div style={wrapperStyles}>
                <div style={itemStyles}>Status</div>
                <div style={itemStyles}>Splitcart ID</div>
                <div style={itemStyles}>Retailer</div>
                <div style={itemStyles}>Organizer</div>
                <div style={itemStyles}>Receiver</div>
            </div>
            <div>
                {props.splitcarts.map(splitcart => {
                    return (
                        <div key={splitcart.id}>
                            <SplitcartListItem splitcart={splitcart} handleSplitcartSelect={props.handleSplitcartSelect}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SplitcartList