import { Reducer } from 'redux';
import {
  SplitcartActions,
  SplitcartActionTypes,
} from '../actions/SplitcartActions';

import { IContact } from '../models/Contact'
import { IOrganizer } from '../models/Organizer'
import { IReturn } from '../models/Return'
import { IProduct } from '../models/Product'
import { IOrder } from '../models/Order'

export interface ISplitcart {
    id: string
    title: string
    endDate: string
    splitterPrice: number
    isFixedCost: boolean
    useReward: boolean
    product?: IProduct
    splitters: IContact[]
    createGiftCard: boolean
    trackingUrl: string
    fundedDate: string
    funded: boolean
    receiverStatus: string
    organizer: IOrganizer
    receiver?: IContact
    returnInfo: IReturn
    organizerTransactionId: string
    wasCanceled: boolean
    cancelDate: string
    percent: number
    order: IOrder
    creationDate: string
}

export interface ISplitcartState {
    readonly splitcarts: ISplitcart[]
}

const initialSplitcartState: ISplitcartState = {
    splitcarts: []
}

export const splitcartReducer: Reducer<ISplitcartState, SplitcartActions> = (
    state = initialSplitcartState,
    action
) => {
    switch(action.type) {
        case SplitcartActionTypes.GET_ALL: {
            return {
                ...state,
                splitcarts: action.splitcarts
            }
        }
        case SplitcartActionTypes.FILTER_SPLITCARTS: {
            return {
                ...state,
                splitcarts: action.splitcarts
            }
        }
        default:
            return state
    }
}