import React from 'react'
import CSS from 'csstype'

import { ISplitcart } from '../reducers/SplitcartReducer'
import { SplitcartViewModel } from '../viewModels/SplitcartViewModel'

interface IProps {
    splitcart: ISplitcart
    handleSplitcartSelect: (id: string) => void
}

const wrapperStyles: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 2fr 2fr',
    padding: '10px'
}

const itemStyles: CSS.Properties = {
    paddingLeft: '20px',
    textAlign: 'left'
}

const SplitcartListItem: React.FunctionComponent<IProps> = (props: IProps) => {

    const viewModel = new SplitcartViewModel(props.splitcart)

    const status = () => {
        if(viewModel.wasCanceled()) {
            return 'Canceled'
        } else if(viewModel.funded()) {
            return 'Funded'
        } else if(!viewModel.isCompleted()) {
            if(viewModel.daysleft() > -1) {
                return 'In Progress'
            } else {
                return 'Failed'
            }
        } else if(viewModel.isCompleted()) {
            return 'Completed'
        }
    }

    const handleClick = (event: React.MouseEvent) => {
        props.handleSplitcartSelect(viewModel.splitcart.id)
    }

    return(
        <div style={wrapperStyles} onClick={handleClick}>
            <div style={itemStyles}>{status()}</div>
            <div style={itemStyles}>{viewModel.splitcart.id}</div>
            <div style={itemStyles}>{viewModel.splitcart.product?.retailer ?? ''}</div>
            <div style={itemStyles}>{viewModel.organizerName()}</div>
            <div style={itemStyles}>{viewModel.receiverName()}</div>
        </div>
    )
}

export default SplitcartListItem