import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyCDYj_Y98bo1NZQa_yRPXulG_CGL22nzoo",
    authDomain: "splitcart.firebaseapp.com",
    databaseURL: "https://splitcart.firebaseio.com",
    projectId: "splitcart",
    storageBucket: "splitcart.appspot.com",
    messagingSenderId: "119511391232",
    appId: "1:119511391232:web:75daa9d64062d684"
  };

firebase.initializeApp(config)

export const getUser = (): Promise<firebase.User | null> => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe()
            resolve(userAuth)
        }, reject)
    })
}

export async function signIn(email: string, password: string): Promise<firebase.User> {
    const userCredentials = await auth.signInWithEmailAndPassword(email, password)
    const user = userCredentials.user

    return user !== null ? Promise.resolve(user) : Promise.reject(null)
}

export function logout() {
    return auth.signOut()
}

export const firestore = firebase.firestore()
export const auth = firebase.auth()

export type FirebaseUser = firebase.User | null