import { Reducer } from 'redux'
import { FirebaseUser } from '../firebase/firebase.util'

import {
    UserActions,
    UserActionTypes
} from '../actions/UserActions'

import { IAddress } from '../models/Address'
import { ICreditCard } from '../models/CreditCard'
import { INotificationSettings } from '../models/NotificationSettings'
import { IReward } from '../models/Reward'

export interface IUser {
    firstName: string
    lastName: string
    email: string
    phone: string
    id: string
    splitcarts: string[]
    participatedInSplitcarts: string[]
    receivedSplitcarts: string[]
    shipping?: IAddress
    creditCards: ICreditCard[]
    notificaitonsSettings: INotificationSettings
    reward: IReward
}

export interface IUserState {
    readonly currentUser: FirebaseUser
    readonly userInfo: IUser | null
}

const initialUserState: IUserState = {
    currentUser: null,
    userInfo: null
}

export const userReducer: Reducer<IUserState, UserActions> = (
    state = initialUserState,
    action
) => {
    switch(action.type) {
        case UserActionTypes.GET_CURRENT_USER: {
            return {
                ...state,
                currentUser: action.currentUser
            }
        }
        case UserActionTypes.SIGN_IN_USER: {
            return {
                ...state,
                currentUser: action.currentUser
            }
        }
        case UserActionTypes.SIGN_OUT_USER: {
            return initialUserState
        }
        default:
            return state
    }
}