import * as React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Redirect } from 'react-router-dom'
import CSS from 'csstype'

import { IAppState } from '../store/store'
import { ISplitcart } from '../reducers/SplitcartReducer'
import { filterSplitcarts } from '../actions/SplitcartActions'
import { signOutUser } from '../actions/UserActions'
import * as Constants from '../constants/routes'

import SplitcartList from '../components/SplitcartList'
import FilterForm from '../components/FilterForm'
import SearchInput from '../components/SearchInput'
import { FirebaseUser } from '../firebase/firebase.util'
import TopBar from '../components/TopBar'
import SplitcartInfo from '../components/SplitcartInfo'

interface IProps {
    splitcarts: ISplitcart[]
    user: FirebaseUser
}

interface IDispatchProps {
    filterSplitcarts: (state: ISearchState) => void
    signOutUser: () => void
}

const containerStyles: CSS.Properties = {
    margin: '20px',
}

export interface ISearchState {
    selectedSplitcart: number
    term: string
    giftCard: boolean
    canceled: boolean
    funded: boolean
    returned: boolean
    inProgress: boolean
}

const INITIAL_STATE: ISearchState = {
    selectedSplitcart: -1,
    term: '',
    giftCard: false,
    canceled: false,
    funded: false,
    returned: false,
    inProgress: false
}

class Search extends React.Component<IProps & IDispatchProps, ISearchState> {
    state: ISearchState = INITIAL_STATE

    constructor(props: IProps & IDispatchProps) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSignOut = this.handleSignOut.bind(this)
    }

    handleSplitcartSelect = (id: string) => {
        const ids = this.props.splitcarts.map(splitcart => splitcart.id)
        const index = ids.indexOf(id)
        this.setState({ selectedSplitcart: index })
    }

    backToList = () => {
        this.setState({ selectedSplitcart: -1 })
        this.props.filterSplitcarts(this.state)
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const key: string = event.currentTarget.name
        const value: string = event.currentTarget.value

        switch(key) {
            case 'term':
                this.setState({ term: value }); break
            case 'giftCard':
                this.setState({ giftCard: !this.state.giftCard }); break
            case 'canceled':
                this.setState({ canceled: !this.state.canceled }); break
            case 'funded':
                this.setState({ funded: !this.state.funded }); break
            case 'returned':
                this.setState({ returned: !this.state.returned }); break
            case 'inProgress':
                this.setState({ inProgress: !this.state.inProgress }); break
            default:
                throw new Error('Something when wrong, invalid key')
        }
    }

    handleReset(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.props.filterSplitcarts(INITIAL_STATE)
        this.setState(INITIAL_STATE)
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.props.filterSplitcarts(this.state)
    }

    handleSignOut() {
        this.props.signOutUser()
    }

    render() {
        const { selectedSplitcart, term } = this.state

        let ui
        let userLoggedIn

        if(this.props.user !== null) {
            userLoggedIn = <TopBar handleSignOut={this.handleSignOut} />
        } else {
            userLoggedIn = <Redirect to={Constants.SIGN_IN} />
        }

        if(selectedSplitcart === -1) {
            ui = <div>
                    <FilterForm 
                        handleChange={this.handleChange} 
                    />
                    <br />
                    <SearchInput 
                        handleChange={this.handleChange}
                        handleReset={this.handleReset} 
                        handleSubmit={this.handleSubmit} 
                        term={term} 
                    />
                    <br />
                    <SplitcartList 
                        splitcarts={this.props.splitcarts}
                        handleSplitcartSelect={this.handleSplitcartSelect} 
                    />
                </div>
        } else {
            ui = <div>
                    <button className="ui left labeled icon button" onClick={this.backToList}>
                        <i className="left arrow icon"></i>
                        Back
                    </button>
                    <SplitcartInfo splitcart={this.props.splitcarts[selectedSplitcart]}/>
                </div>
        }

        return (
            <div style={containerStyles}>
                {userLoggedIn}
                <br />
                {ui}
            </div>
        )
    }
}

const mapStateToProps = (store: IAppState) => {
    return {
        splitcarts: store.splitcartState.splitcarts,
        user: store.userState.currentUser
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, props: IProps): IDispatchProps => {
    return {
        filterSplitcarts: async (state) => {
            dispatch(filterSplitcarts(state))
        },
        signOutUser: async () => {
            dispatch(signOutUser())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)