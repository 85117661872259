import React from 'react'
import CSS from 'csstype'
import { ISplitcart } from '../reducers/SplitcartReducer'
import { SplitcartViewModel } from '../viewModels/SplitcartViewModel'
import { IAddress } from '../models/Address'

interface IProps {
    splitcart: ISplitcart
}

const wrapperStyles: CSS.Properties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '10px',
    maxWidth: '500px',
    marginTop: '20px'
}

const leftItemStyles: CSS.Properties = {
    paddingLeft: '20px',
    textAlign: 'left'
}

const rightItemStyles: CSS.Properties = {
    paddingRight: '20px',
    textAlign: 'right'
}

const SplitcartInfo: React.FunctionComponent<IProps> = (props: IProps) => {

    const viewModel = new SplitcartViewModel(props.splitcart)

    const deliveryAddress = (address: IAddress) => {
        return address.address_line1 + ', ' + address.address_line2 + '\n' + address.city + ', ' + address.state + ', ' + address.zip_code
    }

    const listItems = () => {
        let items: string[] = []
        items.push('Splitcart Id`' + props.splitcart.id)
        items.push('Creation Date`' + props.splitcart.creationDate)
        items.push('Organizer Name`' + viewModel.organizerName())
        items.push('Organizer Email`' + props.splitcart.organizer.email)
        items.push('Organizer Phone`' + props.splitcart.organizer.phone)
        items.push('# of Splitters`' + props.splitcart.splitters.length)
        items.push('Order Date`' + props.splitcart.fundedDate)
        items.push('Delivery Address`' + deliveryAddress(props.splitcart.order.shipping))
        items.push('Reciever Name`' + viewModel.receiverName())
        items.push('Retailer`' + props.splitcart.product?.retailer ?? '')
        items.push('Product Link`' + props.splitcart.product?.productUrl ?? '')
        items.push('Tracking Number`' + props.splitcart.trackingUrl)
        items.push('Organizer acknowledge item given to receiver`' + props.splitcart.receiverStatus)
        
        return items
    }

    return (
        <div>
            {
                listItems().map(item => {
                    const components = item.split('`')
                    if(components[0] === 'Product Link') {
                        return <div style={wrapperStyles} key={components[0]}>
                                <div style={leftItemStyles}>
                                    <h4>{components[0]}</h4>
                                </div>
                                <div style={rightItemStyles}>
                                    <a href={components[1]} target="_blank" rel="noopener noreferrer">link</a>
                                </div>
                            </div>
                    } else {
                        return <div style={wrapperStyles} key={components[0]}>
                                <div style={leftItemStyles}>
                                    <h4>{components[0]}</h4>
                                </div>
                                <div style={rightItemStyles}>{components[1]}</div>
                            </div>
                    }
                })
            }
        </div>
    )
}

export default SplitcartInfo