import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { Provider } from 'react-redux'

import { Store } from 'redux'

import configureStore, { IAppState } from './store/store'
import { getAllSplitcarts } from './actions/SplitcartActions'
import { getCurrentUser } from './actions/UserActions'

import * as Constants from './constants/routes'

import Search from './containers/Search'
import LoginForm from './components/LoginForm'

interface IProps {
    store: Store<IAppState>
}

const Root: React.FunctionComponent<IProps> = props => {
    return (
        <Provider store={props.store}>
            <Router>
                <Route exact path={Constants.ADMIN} component={Search} />
                <Route exact path={Constants.SIGN_IN} component={LoginForm} />
            </Router>
        </Provider>
    )
}

const store = configureStore()
store.dispatch(getAllSplitcarts())
store.dispatch(getCurrentUser())

ReactDOM.render(
    <Root store={store}/>,
    document.getElementById('root') as HTMLElement
)